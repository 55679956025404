import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { scanLogColumn } from './constant';
import { getAllScans } from '../../utils/api.dashboard';
import { CircularProgress } from '@mui/material';

function Report() {
  const [scan_data, setScansData] = useState([])
  const [loading, setLoading] = useState(false)
  const scan_column = [
    {
      headerName: "S No.",
      valueGetter: "node.rowIndex + 1",
      width: 78,
      sortable: true
    },
    {
      headerName: 'Scanned Time',
      width: 200,
      field: 'detection_time',
      cellStyle: { borderLeft: '1px solid lightgray' },
      headerClass: 'header-border',
      sortable: true
    },
    {
      headerName: 'Product Name',
      width: 250,
      field: 'product_name',
      cellStyle: { borderLeft: '1px solid lightgray' },
      headerClass: 'header-border',
      sortable: true
    },
    {
      headerName: 'SKU Size',
      width: 150,
      field: 'net_weight',
      cellStyle: { borderLeft: '1px solid lightgray' },
      headerClass: 'header-border',
      sortable: true
    },
    {
      headerName: 'Box Count',
      width: 150,
      field: 'box_count',
      cellStyle: { borderLeft: '1px solid lightgray' },
      headerClass: 'header-border',
      sortable: true
    },
    {
      headerName: 'Front Box Image',
      width: 150,
      field: 'front_box_image',
      cellStyle: { borderLeft: '1px solid lightgray' },
      headerClass: 'header-border',
      // sortable: true,
      cellRenderer: function (params) {
        const handleClick = () => {
          const imageUrl = params.value
          window.open(imageUrl)
        };
        return (
          <p className='cursor' style={{ color: 'blue' }} onClick={handleClick}>View Image</p>
        );
      }
    },
    {
      headerName: 'Side Box Image',
      width: 150,
      field: 'side_box_image',
      cellStyle: { borderLeft: '1px solid lightgray' },
      headerClass: 'header-border',
      // sortable: true,
      cellRenderer: function (params) {
        const handleClick = () => {
          const imageUrl = params.value
          window.open(imageUrl)
        };
        return (
          <p className='cursor' style={{ color: 'blue' }} onClick={handleClick}>View Image</p>
        );
      }
    },
    {
      headerName: 'Back Box Image',
      width: 150,
      field: 'back_box_image',
      cellStyle: { borderLeft: '1px solid lightgray' },
      headerClass: 'header-border',
      // sortable: true,
      cellRenderer: function(params) {
        const handleClick = () => {
          const imageUrl = params.value
          window.open(imageUrl)
        };
        return (
          <p className='cursor' style={{color: 'blue'}} onClick={handleClick}>View Image</p>
        );
      }
    },
  ]

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const response = await getAllScans()
        setScansData(response?.data?.data)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <div
      style={{ height: '70%', margin: 10 }}
      className="ag-theme-alpine">
      {loading ? <p><CircularProgress style={{ textAlign: 'center', margin: 30, width: 25, height: 25 }} /></p> :
        <AgGridReact
          rowData={scan_data}
          columnDefs={scan_column}
          pagination={true}
          domLayout={'autoHeight'}
          paginationPageSize={8}
        />}
    </div>
  )
}

export default Report