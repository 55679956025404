export const scanLogColumn = () => {
    return [
        {
            headerName: "S No.",
            valueGetter: "node.rowIndex + 1",
            width: 78,
            sortable: true
        },
        {
            headerName: 'Scanned Time',
            width: 200,
            field: 'detection_time',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Product Name',
            width: 250,
            field: 'product_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'SKU Size',
            width: 150,
            field: 'sku_size',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Box Count',
            width: 150,
            field: 'product_count',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Net Weight',
            width: 150,
            field: 'net_weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        // {
        //     headerName: 'Weight',
        //     width: 150,
        //     field: 'weight',
        //     cellStyle: { borderLeft: '1px solid lightgray' },
        //     headerClass: 'header-border',
        //     sortable: true
        // },
        {
            headerName: 'Front Box Image',
            width: 250,
            field: 'front_box_images',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Side Box Image',
            width: 250,
            field: 'side_box_images',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Back Box Image',
            width: 250,
            field: 'back_box_images',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
    ]
}

export const deliveryChallanColumn = () => {
    return [
        {
            headerName: "S No.",
            valueGetter: "node.rowIndex + 1",
            width: 78,
            sortable: true
        },
        {
            headerName: 'Date',
            width: 300,
            field: 'date',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Product Name',
            field: 'product_name',
            width: 300,
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Vechicle Number',
            width: 280,
            field: 'vehicle_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Net Weight',
            field: 'weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        }
    ]
}

export const trackerColumn = () => {
    return [
        {
            headerName: "S No.",
            valueGetter: "node.rowIndex + 1",
            width: 78,
            sortable: true
        },
        {
            headerName: 'Company Name',
            width: 300,
            field: 'company_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Status',
            field: 'status',
            width: 300,
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Notification',
            width: 280,
            field: 'notification',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Product Name',
            field: 'product_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        }
    ]
}
