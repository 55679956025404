import React from 'react'
import { Route, Routes } from "react-router-dom";
import SideBar from '../sidebar/SideBar';
import Home from '../views/Home';
import Report from '../views/report/Report';
import Users from '../views/Users';
import Alerts from '../views/Alerts';
import DeliveryChallan from '../views/DeliveryChallan';
import DisplayBill from '../views/DisplayBill';
import AddManualData from '../views/AddManualData';
import Tracker from '../views/Tracker';
import AddCompany from '../views/AddCompany';

function Dashboard() {
    return (
        <SideBar>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/report" element={<Report />} />
                <Route path="/users" element={<Users />} />
                <Route path="/alert" element={<Home />} />
                <Route path="/delivery_challan" element={<DeliveryChallan />} />
                <Route path="/display_of_bill" element={<DeliveryChallan />} />
                <Route path="/add_manual_data" element={<AddManualData />} />
                <Route path="/tracker" element={<Tracker />} />
                <Route path="/add_company" element={<AddCompany />} />
                <Route path="/*" element={<>Not Found</>} />
            </Routes>
        </SideBar>
    )
}

export default Dashboard