import { Button, TextField } from '@mui/material'
import React from 'react'

function AddCompany() {
    return (
        <div>
            <div style={{ margin: 20, fontSize: 20 }}>
                <h4>AddCompany</h4>
                <br></br>
                <h5 style={{ color: 'gray' }}>Company name</h5>
                <div style={{ width: '100%', display: 'flex' }}>
                    <TextField style={{ width: '100%', display: 'flex' }} id="standard-basic" label="Enter Company Name" variant="standard" />
                </div>
                <br></br>
                <h5 style={{ color: 'gray' }}>Company Type</h5>
                <div style={{ width: '100%', display: 'flex' }}>
                    <TextField style={{ width: '100%', display: 'flex' }} id="standard-basic" label="Enter Company Type" variant="standard" />
                </div>
                <br></br>
                <div className='d-flex justify-center'>
                    <Button variant="contained">Add Company</Button>
                </div>

            </div>
        </div>
    )
}

export default AddCompany