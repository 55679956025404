import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { deliveryChallanColumn } from './report/constant';

function DeliveryChallan() {
    const delivery_challan_column = deliveryChallanColumn()
    return (
        <div>
            <div
                style={{ height: '70%', margin: 10 }}
                className="ag-theme-alpine">
                <AgGridReact
                    rowData={[]}
                    columnDefs={delivery_challan_column}
                    pagination={true}
                    domLayout={'autoHeight'}
                    paginationPageSize={8}
                />
            </div>
        </div>
    )
}

export default DeliveryChallan