import { BiHome } from "react-icons/bi";
import DescriptionIcon from '@mui/icons-material/Description';
import { HiUsers } from "react-icons/hi";
import { MdAddAlert } from "react-icons/md";

const routes = [
    {
        path: "/dashboard/home",
        name: "Home",
        icon: <BiHome sx={{height:20, width:25}}/>,
    },
    {
        path: "/dashboard/report",
        name: "Report",
        icon: <DescriptionIcon sx={{height:20, width:20}}/>,
    },
    {
        path: "/dashboard/users",
        name: "Manage Users",
        icon: <HiUsers sx={{height:20, width:20}}/>,
    },
    {
        path: "/dashboard/alert",
        name: "Alerts",
        icon: <MdAddAlert sx={{height:20, width:20}}/>,
    },
    {
        path: "/dashboard/delivery_challan",
        name: "Delivery Challan",
        icon: <DescriptionIcon sx={{height:20, width:20}}/>,
    },
    {
        path: "/dashboard/display_of_bill",
        name: "Display Of Bill",
        icon: <DescriptionIcon sx={{height:20, width:20}}/>,
    },
    {
        path: "/dashboard/add_manual_data",
        name: "Add Manual Data",
        icon: <DescriptionIcon sx={{height:20, width:20}}/>,
    },
    {
        path: "/dashboard/tracker",
        name: "Tracker",
        icon: <DescriptionIcon sx={{height:20, width:20}}/>,
    },
    {
        path: "/dashboard/add_company",
        name: "Add Company",
        icon: <DescriptionIcon sx={{height:20, width:20}}/>,
    },
];

export default routes