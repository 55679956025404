import axios from 'axios';

const url = window.config.endpoint;

const api = axios.create({
    baseURL: url,
});

api.interceptors.request.use(
    (config) => {
        // Set the authorization header with the token
        const authToken = 'Here auth token'
        if (authToken) {
            config.headers['Authorization'] = `Bearer ${authToken}`;
        }
        config.headers['Content-Type'] = 'application/json'
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;
