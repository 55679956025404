import * as React from 'react';
import CompanyDropDown from '../widgets/CompanyDropDown';
import { MdAddAlert } from "react-icons/md";

function Header() {
    const [selected_company, setSelectedCompany] = React.useState('');
    return (
        <div>
            <header className='d-flex justify-between' style={{ padding: 30 }}>
                <div className='d-flex justify-between m-t-10'>
                    <h2 className='APP-color gray fs-25 m-t-10'>Welcome {selected_company}<span><em></em>!</span></h2>
                </div>

                <div>
                    <p className='d-flex justify-end p-r-10 fs-20 APP-color'><MdAddAlert/></p>
                    <CompanyDropDown setSelectedCompany ={setSelectedCompany}/>
                </div>
            </header>
        </div>
    )
}

export default Header