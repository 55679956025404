import { NavLink, useNavigate } from "react-router-dom";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs'
import { LuLogOut } from "react-icons/lu";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import routes from './Routes'
import { Divider } from "@mui/material";
import logo from '../assets/images/TrackSureNewLogo.png'
import Header from "../header/Header";

const SideBar = ({ children }) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onLogoutHandle = () => {
    navigate('/login')
  }

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "15%" : "50px",
            transition: {
              duration: 1,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar`}
        >
          <div className="sticky">
            <div className="top_section">
              <AnimatePresence>
                {isOpen ? (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                  >
                    {/* Logo */}
                    <div style={{ width: "100% !important", marginTop: 15 }}>
                      <img src={logo} width='100%' height={100} />
                    </div>

                  </motion.div>
                ) : <div style={{ width: "15% !important", marginTop: 15 }}>
                  <img src={logo} width='100%' height='47px' />
                </div>}
              </AnimatePresence>

            </div>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: isOpen ? 'calc(100vh - 124px)' : 'calc(100vh - 71px)' }}>
              <section className="routes">
                {routes.map((route, index) => {
                  if (route.subRoutes) {
                    return (
                      <SidebarMenu
                        setIsOpen={setIsOpen}
                        route={route}
                        showAnimation={showAnimation}
                        isOpen={isOpen}
                      />
                    );
                  }

                  return (
                    <NavLink
                      to={route.path}
                      key={index}
                      className="link"
                    // activeClassName="active"
                    >
                      <div className="icon">{route.icon}</div>
                      <AnimatePresence>
                        {isOpen && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="link_text"
                          >
                            {route.name}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </NavLink>
                  );
                })}
              </section>

              {!(window.innerWidth <= 768) && <div>
                <Divider style={{ marginBottom: 10 }} />
                <div className="align-right p-r-10 APP-color">
                  <div className="d-flex justify-between" style={{padding: '0px 10px'}}> 
                    <p style={{marginBottom: '5px'}} className="cursor APP-color" onClick={onLogoutHandle}><b><LuLogOut/> Logout</b></p>
                    {/* {isOpen ? <BsArrowLeftCircleFill className="cursor" onClick={toggle} /> : <BsArrowRightCircleFill className="cursor" onClick={toggle} />} */}
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </motion.div>

        <main className="scrollbar APP-background" style={{ width: isOpen ? "85%" : "calc(100% - 50px)" }}>
          <div><Header /></div>
          {children}
        </main>
      </div>
    </>
  );
};

export default SideBar;
