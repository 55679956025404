import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const company_names = [
    "Prompt",
    "Heritage Dairy",
    "Sarhad Dairy",
];

function getStyles(name, company_name, theme) {
    return {
        fontWeight:
            company_name.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function CompanyDropDown({ setSelectedCompany }) {
    const theme = useTheme();
    const [company_name, setCompanyName] = React.useState('');
    React.useEffect(() => {
        setCompanyName(company_names[0])
        setSelectedCompany(company_names[0])
    }, [company_names])

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setCompanyName(value)
        setSelectedCompany(value)
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-name-label">Company</InputLabel>
                <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={company_name}
                    onChange={handleChange}
                    input={<OutlinedInput label="Name" />}
                    MenuProps={MenuProps}
                >
                    {company_names.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, company_name, theme)}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default CompanyDropDown