import React from 'react'

function Home() {
  return (
    <div style={{width: '100%', display: 'flex' , justifyContent: 'center', alignItems: 'center'}}>
      <div className='d-flex justify-center align-center' style={{ width:'90%', backgroundColor: 'white', }}>
          <div style={{width: '33.33%', borderRight: '1px solid lightgray' }}>
            <p className='text-center p-10 APP-color fs-20'><b>0</b></p>
            <p className='text-center p-16'><b>Total box scanned at production room</b></p>
          </div>
          <div style={{width: '33.33%', borderRight: '1px solid lightgray'}}>
            <p className='text-center p-10 APP-color fs-20'><b>0</b></p>
            <p className='text-center p-16'><b>Total box scanned at warehouse entry</b></p>
          </div>
          <div style={{width: '33.33%'}}>
            <p className='text-center p-10 APP-color fs-20'><b>0</b></p>
            <p className='text-center p-16'><b>Total difference in box</b></p>
          </div>
      </div>
    </div>
  )
}

export default Home