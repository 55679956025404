import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../views/Login";
import Dashboard from "./Dashboard";

function AppRoute() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard/*" element={
                    <Dashboard />
                } />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<>Not found</>} />
            </Routes>
        </Router>
    );
}

export default AppRoute;
