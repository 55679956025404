import React, { useCallback, useEffect, useRef, useState, } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Box, Button } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';

function Users() {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([{
        name: "Gopalakrishna",
        phone_no: "+919988776678",
        role: "Admin",
        company: "Amul",
    }, {
        name: "Abhishek",
        phone_no: "+916766543210",
        role: "Incharge",
        company: "Company",
    },
    {
        name: "Yash",
        phone_no: "+919999999999",
        role: "Super Admin",
        company: "Upjao",
    },
    {
        name: "Anand",
        phone_no: "Phone No",
        role: "Admin",
        company: "Company",
    },
    {
        name: "Radheshyam",
        phone_no: "+919999999999",
        role: "Super Admin",
        company: "Upjao",
    },
    {
        name: "Rohit",
        phone_no: "Phone No",
        role: "Admin",
        company: "Company",
    }
]);
    const columnDefs = [
        {
            headerName: "S No.",
            valueGetter: "node.rowIndex + 1",
            width: 78,
            sortable: true
        },
        {
            headerName: 'Name',
            field: 'name',
            width: 300,
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Phone No',
            width: 240,
            field: 'phone_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Role',
            field: 'role',
            width: 240,
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Company',
            field: 'company',
            width: 240,
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Edit',
            cellStyle: { borderLeft: '1px solid lightgray' },
            width: '80%',
            cellRenderer: (params) => (
                <div className='d-flex g-5'>
                    <div className='w-50'
                    // onClick={() => onEditConformation(params)}
                    ><EditIcon className='cursor' /></div>
                </div>
            ),
            headerClass: 'header-border'
        },
        {
            headerName: 'Delete',
            cellStyle: { borderLeft: '1px solid lightgray' },
            width: '80%',
            cellRenderer: (params) => (
                <div className='d-flex g-5'>
                    <div className='w-50'>
                        <DeleteIcon className='cursor'
                        // onClick={() => onDeleteConformation(params)}
                        />
                    </div>
                </div>
            ),
            headerClass: 'header-border'
        },
    ]
    return (
        <div>
            <main className="m-a-20">
                <div>
                    <div className='d-flex justify-end'>
                        <div className='d-flex justify-between'>
                            <Button className='black' startIcon={<DownloadIcon />}>Download Report in CSV</Button>
                            <Button className='black' startIcon={<AddIcon />} 
                            // onClick={() => setIsAddNewUser(true)}
                            >Add</Button>
                        </div>
                    </div>
                </div>

                <div
                    style={{ height: '100%', width: '100%' }}
                    className="ag-theme-alpine m-t-20">
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        pagination={true}
                        domLayout={'autoHeight'}
                        paginationPageSize={8}
                    // columnHoverHighlight={true}
                    />
                </div>
            </main>
        </div>
    )
}

export default Users