import { Button, TextField } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../assets/images/TrackSureNewLogo.png'

function Login() {
  const navigate = useNavigate()
  const [phone_number, setPhoneNumber] = React.useState('')
  const [password, setPassword] = React.useState('')

  const onLoginSubmit = () => {
    navigate('/dashboard/home')
  }

  return (
    <div className='d-flex justify-center align-center' style={{ margin: 200 }}>
      <div className='d-flex justify-center align-center' style={{ width: '50%' }}>
        <img src={logo} alt="logo" width={'50%'} />

      </div>
      <form onSubmit={(e) => {
        e.preventDefault();
        onLoginSubmit();
      }} style={{ width: '50%' }}>
        <div className='d-flex justify-center align-center' style={{ width: '50%', flexDirection: 'column', gap: 20 }}>
          <h3><b>LINKAGE</b></h3>
          <div>
            <TextField required style={{ width: 300 }} id="outlined-basic" label="Phone Number" onChange={(e) => setPhoneNumber(e.target.value)} variant="outlined" />
          </div>
          <div>
            <TextField required style={{ width: 300 }} id="outlined-basic" label="Password" onChange={(e) => setPassword(e.target.value)} variant="outlined" />
          </div>
          <Button variant="outlined" style={{ width: 200 }} type='submit'><b>Login</b></Button>
        </div>
      </form>

    </div>
  )
}

export default Login